/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    blockquote: "blockquote",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/Mapa-imperio-%C3%A1rabe.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 400px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 67.72151898734178%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHQpcgpYZGP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAERIhQf/aAAgBAQABBQKiIzbW64UDTAT/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAhEh/9oACAEDAQE/AcXJ/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhYf/aAAgBAgEBPwGNU//EABcQAAMBAAAAAAAAAAAAAAAAAAAQEQH/2gAIAQEABj8CI5q//8QAGxABAAICAwAAAAAAAAAAAAAAAQARUWEQITH/2gAIAQEAAT8hNiOd1GyZMAQ54oE8uf/aAAwDAQACAAMAAAAQHw//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EKg64f/EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QZ2obP//EABoQAQEAAwEBAAAAAAAAAAAAAAERACFBMVH/2gAIAQEAAT8QCjdPTeB6IQ0V7jg9/XEKO3mOFGbH5lagDOZ//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Mapa-imperio-árabe\"\n        title=\"Mapa-imperio-árabe\"\n        src=\"/static/ee21eff85659f326389a611375af68f1/066f9/Mapa-imperio-%C3%A1rabe-400x271.jpg\"\n        srcset=\"/static/ee21eff85659f326389a611375af68f1/ff44c/Mapa-imperio-%C3%A1rabe-400x271.jpg 158w,\n/static/ee21eff85659f326389a611375af68f1/a6688/Mapa-imperio-%C3%A1rabe-400x271.jpg 315w,\n/static/ee21eff85659f326389a611375af68f1/066f9/Mapa-imperio-%C3%A1rabe-400x271.jpg 400w\"\n        sizes=\"(max-width: 400px) 100vw, 400px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Leyendo el artículo de ", React.createElement(_components.a, {
    href: "http://harcajmv.blogspot.com.es/"
  }, "Harca"), " donde hablan sobre ", React.createElement(_components.a, {
    href: "http://harcajmv.blogspot.com.es/2015/01/els-mapes-historics-els-estats_13.html"
  }, "mapas históricos y su verdadero significado"), ", me ha parecido obligatorio hacer una pequeña reseña. En este post nos muestran claramente algo que nos recuerdan siempre en ", React.createElement(_components.a, {
    href: "http://arrsa.org/"
  }, "Arrsa"), " que ", React.createElement(_components.a, {
    href: "http://colabores.net/2013/11/08/el-territorio-no-es-continuo-derivas-situacionistas-portulanos-y-nuevas-representaciones/"
  }, "el territorio es discontinuo"), ", en la época medieval dependía sobre todo de la densidad de población."), "\n", React.createElement(_components.p, null, "Y la explicación es sencilla ¿En este momento, qué preferirías, reinar sobre todo el desierto del Sahara o sobre la isla de Manhathan?"), "\n", React.createElement(_components.p, null, "No tengo más preguntas, señoría."), "\n", React.createElement(_components.p, null, "Además el artículo de Harca, nos pone de manifiesto otro importante concepto, la interpretación del territorio en los reinos feudales y la actual era muy distinta. Los reinos anteriores al absolutismo eran una cuestión de propiedad, que pasaba de padres a hijos, se podía comprar y vender, dividir y unir a conveniencia del rey."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "“Un caballo, un caballo, mi reino por un caballo”")), "\n", React.createElement(_components.p, null, "Ricardo III. William Shakespeare."), "\n"), "\n", React.createElement(_components.p, null, "Con la llegada del absolutismo, la mentalidad de los gobernantes cambia, el territorio comienza a ser algo intocable, como mucho puede crecer o menguar en las disputas con otros reyes. El territorio empieza a parecer algo más ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Biopol%C3%ADtica"
  }, "vivo"), ", como una manta de musgo que se agranda o se encoge dependiendo de su buena o mala salud. Es el caldo de cultivo de los estados que conocemos hoy. Algo ha cambiado."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
